import { apiErrorHandler } from '../apiErrorHandler';
import apiClient from '../axios';
import { Dispatch } from 'react';
import { CORE_URL } from 'src/app/common/network';

export type AppConfigData = {
  type: string;
  namespace: string;
  group: string;
  name: 'global-system' | 'sales-common' | 'insurance-summary-portal' | 'survey-common';
  desc: string;
  content: {
    region: {
      dateFormat: {
        date: string;
        shortDate: string;
        datetime: string;
        longDatetime: string;
        month: string;
        time: string;
        longTime: string;
        utcTime: string;
      };
      lbuCode: string;
    };
    productTypes: {
      id: string;
    }[];
    regionChannelConfig: {
      enableRegion: boolean;
      enableChannel: boolean;
    };
    agent: {
      codeLength: number;
    };
  };
  access: string;
  updatedAt: string;
  createdAt: string;
  id: string;
};

export const fetchAppConfigs = async (
  accessToken: string,
  dispatch?: Dispatch<any>,
): Promise<AppConfigData[] | null> => {
  // const url = `${CORE_URL}/configs/global-system?group=pruforce.Global&access=public`;
  const url = `${CORE_URL}/configs/appConfigs?app=agentPortal`;
  return apiClient
    .get<{ data: AppConfigData[] }>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
