import { TableRow } from "@mui/material";
import { withStyles } from 'tss-react/mui';

export const PruTableRow = withStyles(TableRow, (theme) => ({
  root: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover
    }
  },
}));
