import React, { FC, PropsWithChildren, createContext, useMemo } from 'react';
import { ModuleHierachy } from './types';
import { map } from 'lodash';
import { useIntl } from 'react-intl';
import { surveySubmitModuleConfig } from '../../modules/Survey/module-def';
import { landingModuleConfig } from '../../modules/Landing/module-def';
import { agencyCampaignModuleConfig } from '../../modules/AgencyCampaign/module-def';
import { LitmosModuleConfig } from '../../modules/Litmos/module-def';
import { reviewWebFormModuleConfig } from 'src/app/modules/ReviewWebForm/module-def';
import { GlobalHelper } from '../helpers/GlobalHelpers';
import { SummaryDashboardModuleConfig } from 'src/app/modules/SummaryDashboard/module-def';
import { agentProfileModuleConfig } from 'src/app/modules/AgentProfile/module-def';
import { managerDashboardModuleConfig } from 'src/app/modules/ManagerDashboard/module-def';
import { useJWT } from '../utils/hook-utils';

type ModuleContextInternal = {
  moduleMap: ModuleHierachy[];
};

export const ModuleContext = createContext<ModuleContextInternal>({
  moduleMap: [],
});

const ModuleProvider: FC<PropsWithChildren> = ({ children }) => {
  const intl = useIntl();
  GlobalHelper.setGlobalIntlRef(intl);
  const token = useJWT();
  const moduleMap: ModuleHierachy[] = useMemo(() => {
    if (!token) {
      return [];
    }
    const permissions = map(token.permissions, (_, key) => key);

    return [
      //  Export module configurations here
      landingModuleConfig(permissions, intl),
      surveySubmitModuleConfig(permissions, intl),
      agencyCampaignModuleConfig(permissions, intl),
      reviewWebFormModuleConfig(permissions, intl),
      SummaryDashboardModuleConfig(permissions, intl),
      agentProfileModuleConfig(permissions, intl),
      managerDashboardModuleConfig(permissions, intl),

      // Litmos needs to be the last module
      LitmosModuleConfig(permissions, intl),

      // eslint-disable-next-line
    ];
    // eslint-disable-next-line
  }, [token]);

  return <ModuleContext.Provider value={{ moduleMap }}>{children}</ModuleContext.Provider>;
};

export default ModuleProvider;
